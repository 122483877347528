<template>
  <div>
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXFW:ZLXZ:SFZJ')" class="container">
      <div class="left">
        <div class="left-title">资料类型</div>
        <el-tabs :tab-position="tabPosition">
          <el-tab-pane v-for="(item, index) in navData" :key="index">
            <div slot="label">
              <el-tooltip :disabled="item.length > 11 ? false : true" effect="dark" :content="item" placement="top">
                <div class="text-ellipsis-left" @click="getNowData(item)">{{ item }}</div>
              </el-tooltip>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="query">
        <el-input class="form-control" v-model="title" placeholder="资料主题"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="getNowDataByQuery()">搜索</el-button>
      </div>
      <div class="right">
        <el-table :data="tableData" style="width: 100%" max-height="330">
          <el-table-column prop="title" label="文件名称"></el-table-column>
          <el-table-column prop="ctime" label="日期" :formatter="formatDate"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="downLoadFile(scope.row)" type="warning" size="mini" plain
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>
  </div>
</template>

<script>
import { getPolicyData, fileDetail } from '../../api/file-service';
import { format } from 'date-fns';
import { mapGetters } from 'vuex';
import hasConstruction from '@iebdc/mixin/hasConstruction';

export default {
  mixins: [hasConstruction],
  data() {
    return {
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '198px',
        imgHeight: '154px',
        marginTop: '-108px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      tabPosition: 'left',
      navData: [],
      title: '',
      category: '',
      tableData: [],
      // operatesOne: {
      //   width: '80px',
      //   fixed: 'right',
      //   list: [
      //     {
      //       show: true,
      //       spanType: 'button',
      //       id: '1',
      //       label: '查看',
      //       type: 'success',
      //       method: () => {
      //       },
      //       showCallback: () => {
      //         return true;
      //       }
      //     }
      //   ]
      // },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['selectedOrganizationsCode', 'userType']),
  },
  methods: {
    init() {
      let param = {
        // timestamplp: new Date().getTime(),
        articleTypeEnum: 'ZL',
        visible: true,
        nCurrent: 0,
        nSize: 1000,
      };
      // 获取资料类型
      // getfileType(param).then((res) => {
      //   if (res.success) {
      //     this.navData = res.data;
      //     if (res.data.length) {
      //       this.getNowData(this.navData[0]);
      //     }
      //   }
      // });
      // 上面接口有问题,查询到的文章类型没有去除掉不可见的类型
      getPolicyData(param).then((res) => {
        if (res.data && res.success) {
          const copyData = [...res.data.page];
          copyData.forEach((item) => {
            this.navData.push(item.category);
          });
          this.navData = [...new Set(this.navData)];
          if (this.selectedOrganizationsCode) {
            this.getNowData(this.navData[0]);
          }
        }
      });
    },
    //获取现在的数据发送请求
    getNowData(label) {
      this.category = label;
      let param = {};
      param = {
        articleTypeEnum: 'ZL',
        category: this.category,
        nCurrent: 0,
        visible: true,
        djjg: this.selectedOrganizationsCode,
        title: this.title,
      };
      getPolicyData(param).then((res) => {
        if (res.success) {
          this.tableData = res.data.page;
        }
      });
    },
    // 通过搜索进行查询
    getNowDataByQuery() {
      let param = {
        articleTypeEnum: 'ZL',
        djjg: this.selectedOrganizationsCode,
        category: this.category,
        nCurrent: 0,
        visible: true,
        title: this.title,
      };
      getPolicyData(param).then((res) => {
        if (res.success) {
          this.tableData = res.data.page;
        }
      });
    },
    //文件下载
    downLoadFile(row) {
      if (row.contents === null) {
        return this.$message.warning('资料为空');
      }
      //文件预览
      fileDetail({
        fileId: row.contents,
      })
        .then((res) => {
          if (res.success) {
            // console.log('111', window.CONFIG.unify_ip.split('/'), '1', window.CONFIG.unify_ip.length);
            let fileUrl = res.data;
            window.open(fileUrl, '_blank');
          }
        })
        .catch(() => {});
    },
    formatDate(row) {
      return format(row.ctime, 'yyyy-MM-dd');
    },
  },
};
</script>

<style lang="scss" scoped>
$leftWidth: 200px;

.container {
  position: relative;
  height: 500px;
  background-color: #fff;

  .left {
    width: $leftWidth;
    height: 100%;

    .left-title {
      width: $leftWidth;
      height: 40px;
      // position: relative;
      line-height: 40px;
      text-align: center;
      background-color: #2491e3;
      color: #fff;
    }

    .text-ellipsis-left {
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    /deep/ .el-tabs--left {
      height: 320px !important;
      // height: 96% !important;
    }

    /deep/ .el-tabs__nav-scroll {
      width: $leftWidth;
    }

    /deep/ .el-tabs__active-bar {
      display: none;
    }

    /deep/ .el-tabs__item.is-left {
      text-align: center;
    }

    /deep/ .el-tabs__item.is-active {
      background-color: rgba(76, 180, 251, 0.1);
      color: #666;
      cursor: pointer;
    }
  }

  .query {
    // position: absolute;
    // top: 100px;
    // right: 200px;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 0;

    .form-control {
      width: 220px;
      margin-right: 10px;
    }
  }

  .right {
    position: absolute;
    width: calc(100% - 200px);
    height: calc(100% - 52px);
    top: 52px;
    right: 0;

    /deep/ .el-table {
      border-top: 1px solid rgba(241, 241, 241, 1);
      border-bottom: 1px solid rgba(241, 241, 241, 1);

      th {
        background-color: #f6fafd !important;
        height: 53px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        font-family: Microsoft YaHei !important;
        color: #444444 !important;
      }

      // .el-table__row--striped td {
      //   background-color: rgba(247, 250, 255, 1) !important;
      // }
      // //用来设置当前页面element全局table,hover某行时的背景色
      // .el-table__body tr.hover-row > td {
      //   background-color: #e7f3ff !important;
      //   // color: white;
      //   color: rgba(61, 145, 246, 1);
      // }
      // // 用来设置当前页面element全局table 选中某行时的背景色
      // .el-table__body tr.current-row > td {
      //   background-color: #e7f3ff !important;
      //   color: rgba(61, 145, 246, 1);
      // }
    }
  }
}
</style>
